// [full team name, team abbreviation]
export const TEAMS: Map<string, string> = new Map<string, string>([
    ['Arizona Cardinals', 'ARI'],
    ['Atlanta Falcons', 'ATL'],
    ['Baltimore Ravens', 'BAL'],
    ['Buffalo Bills', 'BUF'],
    ['Carolina Panthers', 'CAR'],
    ['Chicago Bears', 'CHI'],
    ['Cincinnati Bengals', 'CIN'],
    ['Cleveland Browns', 'CLE'],
    ['Dallas Cowboys', 'DAL'],
    ['Denver Broncos', 'DEN'],
    ['Detroit Lions', 'DET'],
    ['Green Bay Packers', 'GB'],
    ['Houston Texans', 'HOU'],
    ['Indianapolis Colts', 'IND'],
    ['Jacksonville Jaguars', 'JAX'],
    ['Kansas City Chiefs', 'KC'],
    ['Los Angeles Chargers', 'LAC'],
    ['Los Angeles Rams', 'LAR'],
    ['Las Vegas Raiders', 'LV'],
    ['Miami Dolphins', 'MIA'],
    ['Minnesota Vikings', 'MIN'],
    ['New England Patriots', 'NE'],
    ['New Orleans Saints', 'NO'],
    ['New York Giants', 'NYG'],
    ['New York Jets', 'NYJ'],
    ['Philadelphia Eagles', 'PHI'],
    ['Pittsburgh Steelers', 'PIT'],
    ['Seattle Seahawks', 'SEA'],
    ['San Francisco 49ers', 'SF'],
    ['Tampa Bay Buccaneers', 'TB'],
    ['Tennessee Titans', 'TEN'],
    ['Washington Football Team', 'WFT'], ['Washington Commanders', 'WAS'], // Should logic be added to consolidate these?
    /* Begin historical teams */
    ['Los Angeles Raiders', 'RAI'],
    ['Oakland Raiders', 'OAK'],
    ['Phoenix Cardinals', 'PHX'],
    ['San Diego Chargers', 'SDC'],
    ['St. Louis Rams', 'STL'],
    ['St. Louis Cardinals', 'STC'],
    ['Tennessee Oilers', 'OIL'],
    ['Houston Oilers', 'HOI'],
    ['Baltimore Colts', 'BLC'],
]);

// [team abbreviation, full team name]
export const ABBREVIATION_TO_TEAM_MAP: Map<string, string> = new Map<string, string>([
    ['ARI', 'Arizona Cardinals'],
    ['ATL', 'Atlanta Falcons'],
    ['BAL', 'Baltimore Ravens'],
    ['BUF', 'Buffalo Bills'],
    ['CAR', 'Carolina Panthers'],
    ['CHI', 'Chicago Bears'],
    ['CIN', 'Cincinnati Bengals'],
    ['CLE', 'Cleveland Browns'],
    ['DAL', 'Dallas Cowboys'],
    ['DEN', 'Denver Broncos'],
    ['DET', 'Detroit Lions'],
    ['GB', 'Green Bay Packers'],
    ['HOU', 'Houston Texans'],
    ['IND', 'Indianapolis Colts'],
    ['JAX', 'Jacksonville Jaguars'],
    ['KC', 'Kansas City Chiefs'],
    ['LAC', 'Los Angeles Chargers'],
    ['LAR', 'Los Angeles Rams'],
    ['LV', 'Las Vegas Raiders'],
    ['MIA', 'Miami Dolphins'],
    ['MIN', 'Minnesota Vikings'],
    ['NE', 'New England Patriots'],
    ['NO', 'New Orleans Saints'],
    ['NYG', 'New York Giants'],
    ['NYJ', 'New York Jets'],
    ['PHI', 'Philadelphia Eagles'],
    ['PIT', 'Pittsburgh Steelers'],
    ['SEA', 'Seattle Seahawks'],
    ['SF', 'San Francisco 49ers'],
    ['TB', 'Tampa Bay Buccaneers'],
    ['TEN', 'Tennessee Titans'],
    ['WFT', 'Washington Football Team'], ['WAS', 'Washington Commanders'], // Should logic be added to consolidate these?
    /* Begin historical teams */
    ['RAI', 'Los Angeles Raiders'],
    ['OAK', 'Oakland Raiders'],
    ['PHX', 'Phoenix Cardinals'],
    ['SDC', 'San Diego Chargers'],
    ['STL', 'St. Louis Rams'],
    ['STC', 'St. Louis Cardinals'],
    ['OIL', 'Tennessee Oilers'],
    ['HOI', 'Houston Oilers'],
    ['BLC', 'Baltimore Colts'],
]);