import { Container } from "react-bootstrap";

export function NotFound() {
    return (
        <Container>
            <h2>
                Page Not Found.
            </h2>
        </Container>
    )
}